import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { AddNewNotificationDrawerComponent } from "../add-new-notification-drawer/add-new-notification-drawer.component";
import { SendEmailDrawerComponent } from "../send-email-drawer/send-email-drawer.component";
import { ActivityLeadMaster } from "src/app/Models/ActivityLeadmaster";
import { PSFActivityLeadMaster } from "src/app/Models/psfaddacivity";
// import { AddNewNotificationDrawerComponent } from '../add-new-notification-drawer/add-new-notification-drawer.component';
// import { SendEmailDrawerComponent } from '../send-email-drawer/send-email-drawer.component';

@Component({
  selector: "app-view-notification-drawer",
  templateUrl: "./view-notification-drawer.component.html",
  styleUrls: ["./view-notification-drawer.component.css"],
})
export class ViewNotificationDrawerComponent implements OnInit {
  constructor(
    public api: ApiService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private cookie: CookieService
  ) {}

  ngOnInit() {}

  pageIndex = 1;
  pageSize = 8;
  totalRecords: number;
  notificationData = [];
  btnLoadMoreStatus = false;
  isSpinning = false;
  LOCATIONs = [];
  userId = Number(this.cookie.get("userId"));
  roleId = Number(this.cookie.get("roleId"));
  orgId = Number(this.cookie.get("orgId"));
  deptId = Number(this.cookie.get("deptId"));
  branchId = Number(this.cookie.get("branchId"));
  designationId = Number(this.cookie.get("designationId"));

  HR;
  SERVICE;
  ttype: any;
  default: any;

  default1: any;
  default2: any;
  default3: any;

  ActivityTypes: any;
  dept() {
    this.api.getAllActivityTypeMaster(0, 0, "", "", " AND STATUS=1").subscribe(
      (data) => {
        this.ActivityTypes = data["data"];
        // this.data.ACTIVITY_TYPE_ID = data['data'][0]['ID']
      },
      (err) => {
        this.isSpinning = false;
      }
    );
  }
  getNotifications(type) {
    if (type == "S") {
      this.ttype = "S";
      this.default1 = "primary";

      this.default = "default";

      this.default2 = "default";
    } else if (type == "H") {
      this.ttype = "H";
      this.default1 = "default";

      this.default = "primary";

      this.default2 = "default";
    } else if (type == "X") {
      this.default1 = "default";

      this.default = "default";

      this.default2 = "primary";
    } else if (type == "IN") {
      this.default1 = "default";

      this.default = "default";

      this.default2 = "default";
      this.default3 = "primary";
    }
    if (this.roleId == 43) {
      if (type == "S") {
        this.isSpinning = true;
        this.notificationData = [];
        this.api
          .getAllMyserviceNotications(
            this.pageIndex,
            this.pageSize,
            this.userId,
            this.deptId,
            this.branchId,
            this.designationId,
            this.orgId,
            " AND TYPE = " +
              "'" +
              this.ttype +
              "'" +
              " AND EMPLOYEE_ID = " +
              this.userId
          )
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.isSpinning = false;
                this.totalRecords = data["count"];
                this.notificationData = data["data"];
                // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

                if (this.totalRecords == this.notificationData.length) {
                  this.btnLoadMoreStatus = false;
                } else {
                  this.btnLoadMoreStatus = true;
                }
              }
            },
            (err) => {
              if (this.api.checkOnlineStatus()) {
                // console.log(err);
                this.message.error(
                  "The server's internet connection is down. Please contact the EDP department for help.",
                  ""
                );
              } else {
                this.message.error(
                  "Cannot perform operation due to unstable Internet connection. ",
                  ""
                );
              }
            }
          );
      } else {
        this.isSpinning = true;
        this.notificationData = [];
        this.api
          .getAllMyNotications(
            this.pageIndex,
            this.pageSize,
            this.userId,
            this.deptId,
            this.branchId,
            this.designationId,
            this.orgId,
            " AND TYPE != " +
              "'" +
              "S" +
              "'" +
              " AND TYPE != " +
              "'" +
              "X" +
              "'" +
              " AND TYPE != " +
              "'" +
              "IN" +
              "'"
          )
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.isSpinning = false;
                this.totalRecords = data["count"];
                this.notificationData = data["data"];
                // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

                if (this.totalRecords == this.notificationData.length) {
                  this.btnLoadMoreStatus = false;
                } else {
                  this.btnLoadMoreStatus = true;
                }
              }
            },
            (err) => {
              if (this.api.checkOnlineStatus()) {
                // console.log(err);
                this.message.error(
                  "The server's internet connection is down. Please contact the EDP department for help.",
                  ""
                );
              } else {
                this.message.error(
                  "Cannot perform operation due to unstable Internet connection. ",
                  ""
                );
              }
            }
          );
      }
    } else if (this.roleId == 50) {
      if (type == "H") {
        this.isSpinning = true;
        this.notificationData = [];
        this.api
          .getAllMyNotications(
            this.pageIndex,
            this.pageSize,
            this.userId,
            this.deptId,
            this.branchId,
            this.designationId,
            this.orgId,
            " AND TYPE != " +
              "'" +
              "S" +
              "'" +
              " AND TYPE != " +
              "'" +
              "X" +
              "'" +
              " AND TYPE != " +
              "'" +
              "IN" +
              "'"
          )
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.isSpinning = false;
                this.totalRecords = data["count"];
                this.notificationData = data["data"];
                // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

                if (this.totalRecords == this.notificationData.length) {
                  this.btnLoadMoreStatus = false;
                } else {
                  this.btnLoadMoreStatus = true;
                }
              }
            },
            (err) => {
              if (this.api.checkOnlineStatus()) {
                // console.log(err);
                this.message.error(
                  "The server's internet connection is down. Please contact the EDP department for help.",
                  ""
                );
              } else {
                this.message.error(
                  "Cannot perform operation due to unstable Internet connection. ",
                  ""
                );
              }
            }
          );
      } else if (type == "X") {
        this.isSpinning = true;
        this.notificationData = [];
        this.api
          .getAllMyserviceNotications(
            this.pageIndex,
            this.pageSize,
            this.userId,
            this.deptId,
            this.branchId,
            this.designationId,
            this.orgId,
            " AND TYPE = " +
              "'" +
              "X" +
              "'" +
              " AND EMPLOYEE_ID = " +
              this.userId
          )
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.isSpinning = false;
                this.totalRecords = data["count"];
                this.notificationData = data["data"];
                // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

                if (this.totalRecords == this.notificationData.length) {
                  this.btnLoadMoreStatus = false;
                } else {
                  this.btnLoadMoreStatus = true;
                }
              }
            },
            (err) => {
              if (this.api.checkOnlineStatus()) {
                // console.log(err);
                this.message.error(
                  "The server's internet connection is down. Please contact the EDP department for help.",
                  ""
                );
              } else {
                this.message.error(
                  "Cannot perform operation due to unstable Internet connection. ",
                  ""
                );
              }
            }
          );
      }
    } else if (this.roleId == 51) {
      if (type == "H") {
        this.isSpinning = true;
        this.notificationData = [];
        this.api
          .getAllMyNotications(
            this.pageIndex,
            this.pageSize,
            this.userId,
            this.deptId,
            this.branchId,
            this.designationId,
            this.orgId,
            " AND TYPE != " +
              "'" +
              "S" +
              "'" +
              " AND TYPE != " +
              "'" +
              "X" +
              "'" +
              " AND TYPE != " +
              "'" +
              "IN" +
              "'"
          )
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.isSpinning = false;
                this.totalRecords = data["count"];
                this.notificationData = data["data"];

                // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

                if (this.totalRecords == this.notificationData.length) {
                  this.btnLoadMoreStatus = false;
                } else {
                  this.btnLoadMoreStatus = true;
                }
              }
            },
            (err) => {
              if (this.api.checkOnlineStatus()) {
                // console.log(err);
                this.message.error(
                  "The server's internet connection is down. Please contact the EDP department for help.",
                  ""
                );
              } else {
                this.message.error(
                  "Cannot perform operation due to unstable Internet connection. ",
                  ""
                );
              }
            }
          );
      } else if (type == "IN") {
        this.isSpinning = true;
        this.notificationData = [];
        this.api
          .getAllMyNotications(
            this.pageIndex,
            this.pageSize,
            this.userId,
            this.deptId,
            this.branchId,
            this.designationId,
            this.orgId,
            " AND TYPE != " +
              "'" +
              "S" +
              "'" +
              " AND TYPE != " +
              "'" +
              "X" +
              "'"
          )
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.isSpinning = false;
                this.totalRecords = data["count"];
                this.notificationData = data["data"];
                // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

                if (this.totalRecords == this.notificationData.length) {
                  this.btnLoadMoreStatus = false;
                } else {
                  this.btnLoadMoreStatus = true;
                }
              }
            },
            (err) => {
              if (this.api.checkOnlineStatus()) {
                // console.log(err);
                this.message.error(
                  "The server's internet connection is down. Please contact the EDP department for help.",
                  ""
                );
              } else {
                this.message.error(
                  "Cannot perform operation due to unstable Internet connection. ",
                  ""
                );
              }
            }
          );
      }
    } else {
      this.isSpinning = true;
      this.notificationData = [];
      this.api
        .getAllMyNotications(
          this.pageIndex,
          this.pageSize,
          this.userId,
          this.deptId,
          this.branchId,
          this.designationId,
          this.orgId,
          " AND TYPE != " + "'" + "S" + "'" + " AND TYPE != " + "'" + "X" + "'"
        )
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.isSpinning = false;
              this.totalRecords = data["count"];
              this.notificationData = data["data"];
              // const newData = data['data'];

              // this.notificationData = [...this.notificationData, ...newData];
              // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

              if (this.totalRecords == this.notificationData.length) {
                this.btnLoadMoreStatus = false;
              } else {
                this.btnLoadMoreStatus = true;
              }
            }
          },
          (err) => {
            if (this.api.checkOnlineStatus()) {
              // console.log(err);
              this.message.error(
                "The server's internet connection is down. Please contact the EDP department for help.",
                ""
              );
            } else {
              this.message.error(
                "Cannot perform operation due to unstable Internet connection. ",
                ""
              );
            }
          }
        );
    }
  }

  src(ATTACHMENT) {
    if (ATTACHMENT != "" || ATTACHMENT != null) {
      var urll = ATTACHMENT.slice(ATTACHMENT.lastIndexOf(".") + 1);
      // var filename = ATTACHMENT.slice(ATTACHMENT.lastIndexOf('/') + 1)

      return urll;
    }
  }
  drawerVisible1: boolean;
  drawerClose2(): void {
    if (this.roleId == 50) {
      this.drawerVisible11 = false;
    } else {
      this.drawerVisible1 = false;
    }
    // this.getAllcount();
    this.datalist = [];
    if (this.roleId == 43) {
      this.isSpinning = true;
      this.notificationData = [];
      this.api
        .getAllMyserviceNotications(
          this.pageIndex,
          this.pageSize,
          this.userId,
          this.deptId,
          this.branchId,
          this.designationId,
          this.orgId,
          " AND TYPE = " +
            "'" +
            this.ttype +
            "'" +
            " AND EMPLOYEE_ID = " +
            this.userId
        )
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.isSpinning = false;
              this.totalRecords = data["count"];
              this.notificationData = data["data"];
              // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

              if (this.totalRecords == this.notificationData.length) {
                this.btnLoadMoreStatus = false;
              } else {
                this.btnLoadMoreStatus = true;
              }
            }
          },
          (err) => {
            if (this.api.checkOnlineStatus()) {
              // console.log(err);
              this.message.error(
                "The server's internet connection is down. Please contact the EDP department for help.",
                ""
              );
            } else {
              this.message.error(
                "Cannot perform operation due to unstable Internet connection. ",
                ""
              );
            }
          }
        );
    } else if (this.roleId == 50) {
      this.isSpinning = true;
      this.notificationData = [];
      this.api
        .getAllMyserviceNotications(
          this.pageIndex,
          this.pageSize,
          this.userId,
          this.deptId,
          this.branchId,
          this.designationId,
          this.orgId,
          " AND TYPE = " + "'" + "X" + "'" + " AND EMPLOYEE_ID = " + this.userId
        )
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.isSpinning = false;
              this.totalRecords = data["count"];
              this.notificationData = data["data"];
              // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

              if (this.totalRecords == this.notificationData.length) {
                this.btnLoadMoreStatus = false;
              } else {
                this.btnLoadMoreStatus = true;
              }
            }
          },
          (err) => {
            if (this.api.checkOnlineStatus()) {
              // console.log(err);
              this.message.error(
                "The server's internet connection is down. Please contact the EDP department for help.",
                ""
              );
            } else {
              this.message.error(
                "Cannot perform operation due to unstable Internet connection. ",
                ""
              );
            }
          }
        );
    }
    // this.api.getAllMyserviceNotications(this.pageIndex, this.pageSize, this.userId, this.deptId, this.branchId, this.designationId, this.orgId, ' AND TYPE = ' + "'S'").subscribe(data => {
    //   if (data['code'] == 200) {

    //     this.isSpinning = false;
    //     this.totalRecords = data['count'];
    //     this.notificationData = data['data'];
    //     // var urll = data['data']['ATTACHMENT'].slice(data['data']['ATTACHMENT'].lastIndexOf('.') + 1)

    //     if (this.totalRecords == this.notificationData.length) {
    //       this.btnLoadMoreStatus = false;

    //     } else {
    //       this.btnLoadMoreStatus = true;
    //     }
    //   }

    // }, err => {
    //   if (err['ok'] == false)
    //     this.message.error("Server Not Found", "");
    // });
  }

  get closeCallback2() {
    // this.index=0;
    return this.drawerClose2.bind(this);
  }

  allHolidayList = [];
  today =
    new Date().getFullYear().toString() +
    "-" +
    (new Date().getMonth() + 1).toString();
  month = this.today;
  getHolidayList() {
    this.allHolidayList = [];
    var splitmonth = this.month.split("-", -1);
    this.api
      .getAllHolidays(
        0,
        0,
        "",
        "asc",
        " AND MONTH(DATE) = '" +
          splitmonth[1] +
          "' AND YEAR(DATE) = '" +
          splitmonth[0] +
          "'"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.allHolidayList = data["data"];
          }
        },
        (err) => {
          if (this.api.checkOnlineStatus()) {
            // console.log(err);
            this.message.error(
              "The server's internet connection is down. Please contact the EDP department for help.",
              ""
            );
          } else {
            this.message.error(
              "Cannot perform operation due to unstable Internet connection. ",
              ""
            );
          }
        }
      );
  }

  datalist = [];
  drawerTitle1: string;
  drawerData2: ActivityLeadMaster = new ActivityLeadMaster();
  drawerData22: PSFActivityLeadMaster = new PSFActivityLeadMaster();

  // preventSingleClick = false;

  timer: any;

  delay: Number;

  // singleClick() {

  //   this.preventSingleClick = false;

  //   const delay = 200;

  //   this.timer = setTimeout(() => {

  //     if (!this.preventSingleClick) {

  //       //Navigate on single click

  //     }

  //   }, delay);

  // }

  CUSTOMER_ID;
  CALL_STATUSS: any;
  IS_CONFIRMATION_DONE: boolean;
  NOTIFICATION_ID: any;

  EmployeeList24;
  getEmployeeList1() {
    this.EmployeeList24 = [];

    this.api
      .getAllEmpRoleMap(0, 0, "", "", " AND ROLE_ID=48")
      .subscribe((data) => {
        if (data["code"] == 200) {
          this.EmployeeList24 = data["data"];
        }
      });
  }
  ok11: boolean = false;
  drawerVisible11;
  customername: any;
  mobileno: any;
  feedbackdate: any;
  RO_NUMBER: any;
  ActivityTypescc: any;
  // ActivityTypes:any
  Activity(dataa) {
    // this.preventSingleClick = true;
    this.dept();

    this.customername = dataa.CUSTOMER_NAME;
    this.mobileno = dataa.MOBILE_NUMBER;

    clearTimeout(this.timer);
    if (dataa.CUSTOMER_ID != undefined || dataa.CUSTOMER_ID != null) {
      this.drawerTitle1 = "View Activities";
      if (this.roleId != 45) {
        this.api
          .getAllActivityTypeMaster(0, 0, "", "", " AND STATUS=1 AND ID=2 ")
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.ActivityTypescc = data["data"];
                this.drawerData2.ACTIVITY_TYPE_ID = data["data"][0]["ID"];
              }
            },
            (err) => {
              // this.isSpinning = false;
            }
          );
      }
      if (this.roleId == 45) {
        this.api
          .getAllActivityTypeMaster(0, 0, "", "", " AND STATUS=1")
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.ActivityTypes = data["data"];
                this.drawerData2.ACTIVITY_TYPE_ID = data["data"][2]["ID"];
              }
            },
            (err) => {
              // this.isSpinning = false;
            }
          );
      }
      // [ActivityTypescc]="ActivityTypescc" [ActivityTypes]="ActivityTypes"
      if (this.roleId == 50) {
        this.drawerVisible11 = true;
        this.drawerData22 = Object.assign({}, dataa);
        this.drawerData22.ACTIVITY_TYPE_ID = 2;

        this.drawerData22.CALL_STATUS = "C";
        this.drawerData22.LEAD_ID = dataa.CUSTOMER_ID;
        this.RO_NUMBER = this.drawerData2.RO_NUMBER;
        // this.drawerData22.CALL_TYPE = "O"
        this.feedbackdate = this.drawerData22.FOLLOW_UP_DATE;
        // this.customername = this.drawerData2.CUSTOMER_NAME
        // this.modile_no = this.drawerData2.MOBILE_NUMBER

        this.drawerData22.CALLING_DATE = new Date();
        this.drawerData22.CALLING_TIME = new Date();

        this.api.getAllBranch(0, 0, "", "", " AND STATUS=1").subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.LOCATIONs = data["data"];
            }
          },
          (err) => {
            // this.isSpinning = false;
          }
        );

        this.api
          .getAllActivityTypeMaster(0, 0, "", "", " AND STATUS=1")
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.ActivityTypes = data["data"];
                this.drawerData22.ACTIVITY_TYPE_ID = data["data"][0]["ID"];
              }
            },
            (err) => {
              // this.isSpinning = false;
            }
          );

        this.NOTIFICATION_ID = dataa.ID;

        this.ok11 = true;

        this.api
          .getAllPSFActivityleadMaster(
            1,
            this.pageSize,
            "",
            "",
            " AND LEAD_ID = " + dataa.CUSTOMER_ID
          )
          .subscribe(
            (data) => {
              if (data["code"] == 200 && data["count"] > 0)
                this.datalist = data["data"];
            },
            (err) => {
              // console.log(err);
            }
          );
      } else {
        this.drawerVisible1 = true;
        this.drawerData2 = Object.assign({}, dataa);
        this.drawerData2.ACTIVITY_TYPE_ID = 2;

        this.drawerData2.CALL_STATUS = "R";
        this.drawerData2.CALL_TYPE = "O";

        this.drawerData2.CALLING_DATE_TIME = new Date();
        this.drawerData2.CALLING_TIME = new Date();

        this.NOTIFICATION_ID = dataa.ID;

        if (
          this.drawerData2.CALLING_STATUS == "A" &&
          this.drawerData2.CATEGORY_STATUS == "C" &&
          this.drawerData2.IS_CONFIRMATION_DONE == 0
        ) {
          this.IS_CONFIRMATION_DONE = true;
        } else {
          this.IS_CONFIRMATION_DONE = false;
        }

        this.CUSTOMER_ID = dataa.CUSTOMER_ID;
        this.ok11 = true;
        this.CALL_STATUSS = "C";

        // this.drawerData2.ca

        this.api.getAllBranch(0, 0, "", "", " AND STATUS=1").subscribe(
          (data) => {
            this.LOCATIONs = data["data"];

            var i = -1;
            i = this.LOCATIONs.findIndex((x) => x.ID == this.branchId);
            var roles = this.LOCATIONs[i]["ID"];
            if (i > -1) {
              this.drawerData2.APPOINTMENT_BRANCH = roles;
            }

            if (
              this.drawerData2.SERVICE_ADVISER_ID != undefined ||
              this.drawerData2.SERVICE_ADVISER_ID != null ||
              this.drawerData2.SERVICE_ADVISER_ID != ""
            ) {
              this.api
                .getAllemployeeMaster(
                  0,
                  0,
                  "",
                  "asc",
                  ` AND ORG_ID = ${this.orgId} AND ROLE_IDS LIKE '%48%' AND BRANCH_ID = '${roles}' `
                )
                .subscribe(
                  (data) => {
                    if (data["code"] == 200) {
                      this.EmployeeList24 = data["data"];
                    }
                  },
                  (err) => {
                    if (this.api.checkOnlineStatus()) {
                      // console.log(err);
                      this.message.error(
                        "The server's internet connection is down. Please contact the EDP department for help.",
                        ""
                      );
                    } else {
                      this.message.error(
                        "Cannot perform operation due to unstable Internet connection. ",
                        ""
                      );
                    }
                  }
                );
            }
          },
          (err) => {
            // this.isSpinning = false;
          }
        );
        this.api
          .getAllActivityleadMaster(
            0,
            0,
            "ID",
            "desc",
            " AND CUSTOMER_ID = " + dataa.CUSTOMER_ID
          )
          .subscribe(
            (data) => {
              if (data["code"] == 200) {
                this.datalist = data["data"];
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }

      this.getHolidayList();
    }
    this.getEmployeeList1();
  }

  onLoadMoreClick() {
    // this.pageIndex++
    this.pageSize = this.pageSize + 8;
    this.getNotifications(this.ttype);
  }

  sendNotiDrawerVisible = false;
  sendNotiDrawerTitle: string;

  @ViewChild(AddNewNotificationDrawerComponent, { static: false })
  AddNewNotificationDrawerComponentVar: AddNewNotificationDrawerComponent;

  openSendNotiDrawer() {
    this.sendNotiDrawerVisible = true;
    this.sendNotiDrawerTitle = "Send Notification";
    this.AddNewNotificationDrawerComponentVar.sharingMode = "I";
    this.AddNewNotificationDrawerComponentVar.disableRadioButtons();
    this.AddNewNotificationDrawerComponentVar.changeRadioButton("I");
    this.AddNewNotificationDrawerComponentVar.NOTI_TYPE = "T";
  }

  sendNotiDrawerClose() {
    this.sendNotiDrawerVisible = false;
    this.pageSize = 8;
    this.getNotifications(this.ttype);
  }

  get sendNotiDrawerCloseCallback() {
    return this.sendNotiDrawerClose.bind(this);
  }

  sendEmailDrawerVisible = false;
  sendEmailDrawerTitle: string;

  @ViewChild(SendEmailDrawerComponent, { static: false })
  SendEmailDrawerComponentVar: SendEmailDrawerComponent;

  openSendEmailDrawer() {
    this.sendEmailDrawerVisible = true;
    this.sendEmailDrawerTitle = "Send Email";

    this.SendEmailDrawerComponentVar.FROM_DATE = this.datePipe.transform(
      new Date(),
      "yyyy-MM-dd"
    );
    this.SendEmailDrawerComponentVar.TO_DATE = this.datePipe.transform(
      new Date(),
      "yyyy-MM-dd"
    );
    this.SendEmailDrawerComponentVar.getCount();
  }

  sendEmailDrawerClose() {
    this.sendEmailDrawerVisible = false;
  }

  get sendEmailDrawerCloseCallback() {
    return this.sendEmailDrawerClose.bind(this);
  }

  openImage(imgURL: string) {
    window.open(imgURL);
  }
}
