import { HttpEventType } from "@angular/common/http";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";

@Component({
  selector: "app-add-new-notification-drawer",
  templateUrl: "./add-new-notification-drawer.component.html",
  styleUrls: ["./add-new-notification-drawer.component.css"],
})
export class AddNewNotificationDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  sharingMode = "I";
  USER_IDS = [];
  TITLE = "";
  DESCRIPTION = "";
  employeeList = [];
  isLoadingOne = false;
  userId = Number(this.cookie.get("userId"));
  roleId = Number(this.cookie.get("roleId"));
  orgId = Number(this.cookie.get("orgId"));
  deptId = Number(this.cookie.get("deptId"));
  branchId = Number(this.cookie.get("branchId"));
  designationId = Number(this.cookie.get("designationId"));

  heading = "";

  individualGrid = false;
  deptGrid = false;
  branchGrid = false;
  designationGrid = false;
  entireOrg = false;
  isSpinning = false;
  NOTI_TYPE = "T";
  fileName = "";
  fileUpload = "";
  @ViewChild("iframeElement", { static: false }) iframeElement!: ElementRef;

  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    private cookie: CookieService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {}

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
    this.fileURL = null;
    this.imageURL = null;
    this.photoURL = null;
    this.uploadedAttachmentStr = "";
    // this.clear();
  }
  notiChange(event: any) {
    this.NOTI_TYPE = event;

    this.fileURL = null;
    this.photoURL = null;
    if (this.NOTI_TYPE == "F") {
      this.fileName = "Upload File";
    } else if (this.NOTI_TYPE == "V") {
      this.fileName = "Upload Video";
    } else if (this.NOTI_TYPE == "I") {
      this.fileName = "Upload Image";
    }
  }

  btnIndividualStatus = false;
  btnDepartmentStatus = false;
  btnBranchStatus = false;
  btnDesignationStatus = false;
  btnEntireOrganisationStatus = false;

  disableRadioButtons() {
    if (this.roleId == 12) {
      this.btnIndividualStatus = true;
      this.btnDepartmentStatus = true;
      this.btnBranchStatus = true;
      this.btnDesignationStatus = true;
      this.btnEntireOrganisationStatus = true;
    } else {
      this.btnIndividualStatus = true;
      this.btnDepartmentStatus = false;
      this.btnBranchStatus = false;
      this.btnDesignationStatus = false;
      this.btnEntireOrganisationStatus = false;

      if (this.deptId == 0) {
        this.btnDepartmentStatus = true;
      }

      if (this.designationId == 0) {
        this.btnDesignationStatus = true;
      }

      if (this.branchId == 0) {
        this.btnBranchStatus = true;
      }
    }
  }

  changeRadioButton(btnValue) {
    this.USER_IDS = [];
    this.employeeList = [];

    if (btnValue == "I") {
      this.heading = "Select Employee";

      this.individualGrid = true;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = false;
      this.entireOrg = false;
      this.isSpinning = true;
      this.employeeList = [];
      this.api
        .getNotiSubordinateList(this.userId, this.roleId, this.orgId)
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.employeeList = data["data"];
              this.isSpinning = false;
            } else {
              this.message.error("Can't Load Data", "");
              this.employeeList = [];
              this.isSpinning = false;
            }
          },
          (err) => {
            if (this.api.checkOnlineStatus()) {
              console.log(err);
              this.message.error(
                "The server's internet connection is down. Please contact the EDP department for help.",
                ""
              );
              this.isSpinning = false;
            } else {
              this.message.error(
                "Cannot perform operation due to unstable Internet connection. ",
                ""
              );
              this.isSpinning = false;
            }
          }
        );
    } else if (btnValue == "D") {
      this.heading = "Select Department";

      this.individualGrid = false;
      this.deptGrid = true;
      this.branchGrid = false;
      this.designationGrid = false;
      this.entireOrg = false;
      this.isSpinning = true;
      this.employeeList = [];
      this.api
        .getNotiSubordinateDepartmentList(this.userId, this.orgId)
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.employeeList = data["data"];
              this.isSpinning = false;
            } else {
              this.message.error("Can't Load Data", "");
              this.employeeList = [];
              this.isSpinning = false;
            }
          },
          (err) => {
            if (this.api.checkOnlineStatus()) {
              console.log(err);
              this.message.error(
                "The server's internet connection is down. Please contact the EDP department for help.",
                ""
              );
              this.isSpinning = false;
            } else {
              this.message.error(
                "Cannot perform operation due to unstable Internet connection. ",
                ""
              );
              this.isSpinning = false;
            }
          }
        );
    } else if (btnValue == "B") {
      this.heading = "Select Branch";

      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = true;
      this.designationGrid = false;
      this.entireOrg = false;
      this.isSpinning = true;
      this.employeeList = [];
      this.api.getNotiSubordinateBranchList(this.userId, this.orgId).subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.employeeList = data["data"];
            this.isSpinning = false;
          } else {
            this.message.error("Can't Load Data", "");
            this.employeeList = [];
            this.isSpinning = false;
          }
        },
        (err) => {
          if (this.api.checkOnlineStatus()) {
            console.log(err);
            this.message.error(
              "The server's internet connection is down. Please contact the EDP department for help.",
              ""
            );
            this.isSpinning = false;
          } else {
            this.message.error(
              "Cannot perform operation due to unstable Internet connection. ",
              ""
            );
            this.isSpinning = false;
          }
        }
      );
    } else if (btnValue == "DE") {
      this.heading = "Select Designation";

      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = true;
      this.entireOrg = false;
      this.isSpinning = true;
      this.employeeList = [];
      this.api
        .getNotiSubordinateDesignationList(this.userId, this.orgId)
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.employeeList = data["data"];
              this.isSpinning = false;
            } else {
              this.message.error("Can't Load Data", "");
              this.employeeList = [];
              this.isSpinning = false;
            }
          },
          (err) => {
            if (this.api.checkOnlineStatus()) {
              console.log(err);
              this.message.error(
                "The server's internet connection is down. Please contact the EDP department for help.",
                ""
              );
              this.isSpinning = false;
            } else {
              this.message.error(
                "Cannot perform operation due to unstable Internet connection. ",
                ""
              );
              this.isSpinning = false;
            }
          }
        );
    } else if (btnValue == "EO") {
      this.heading = "Select Employee";

      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = false;
      this.entireOrg = true;
    }
  }

  imageURL;

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  save(myForm: NgForm): void {
    var isOk = true;

    if (!this.entireOrg) {
      if (this.USER_IDS.length == 0) {
        isOk = false;

        if (this.individualGrid) {
          this.message.error("Please Select Valid Employee", "");
        } else if (this.deptGrid) {
          this.message.error("Please Select Valid Department", "");
        } else if (this.branchGrid) {
          this.message.error("Please Select Valid Branch", "");
        } else if (this.designationGrid) {
          this.message.error("Please Select Valid Designation", "");
        }
      }
    }

    if (this.TITLE != undefined) {
      if (this.TITLE.trim() != "") {
        // if (!this.api.checkTextBoxIsValid(this.TITLE)) {
        //   isOk = false;
        //   this.message.error("Please Enter Valid Notification Title", "");
        // }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Notification Title", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Notification Title", "");
    }

    if (this.DESCRIPTION != undefined) {
      if (this.DESCRIPTION.trim() == "") {
        isOk = false;
        this.message.error("Please Enter Valid Notification Description", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Notification Description", "");
    }

    if (this.NOTI_TYPE == "F") {
      if (this.fileURL == null) {
        isOk = false;
        this.message.error("Please Select Valid File", "");
      }
    }
    if (this.NOTI_TYPE == "I") {
      if (this.fileURL == null) {
        isOk = false;
        this.message.error("Please Select Valid Image", "");
      }
    }
    if (this.NOTI_TYPE == "V") {
      if (this.fileURL == null) {
        isOk = false;
        this.message.error("Please Select Valid Video", "");
      }
    }

    if (isOk) {
      this.imageURL = "";
      // this.imageUpload();
      // this.uploadfile(true)
      this.imageURL = this.uploadedAttachmentStr;

      if (this.individualGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1["EMPLOYEE_ID"] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }
        } else {
          var obj1 = new Object();
          obj1["EMPLOYEE_ID"] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api
          .notiDetailsAddBulk(
            this.userId,
            this.TITLE,
            this.DESCRIPTION,
            1,
            a,
            this.orgId,
            this.imageURL,
            this.NOTI_TYPE
          )
          .subscribe(
            (successCode) => {
              if (successCode["code"] == "200") {
                this.isSpinning = false;
                this.message.success("Notification Send Successfully", "");
                this.changeRadioButton("I");
                this.close(myForm);
                this.imageURL = null;
                this.uploadedAttachmentStr = "";
                this.photoURL = null;
                this.fileURL = null;
              } else {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            },
            (err) => {
              if (err["ok"] == false) {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            }
          );
      } else if (this.deptGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1["DEPARTMENT_ID"] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }
        } else {
          var obj1 = new Object();
          obj1["DEPARTMENT_ID"] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api
          .notiDetailsAddBulk(
            this.userId,
            this.TITLE,
            this.DESCRIPTION,
            2,
            a,
            this.orgId,
            this.imageURL,
            this.NOTI_TYPE
          )
          .subscribe(
            (successCode) => {
              if (successCode["code"] == "200") {
                this.isSpinning = false;
                this.message.success("Notification Send Successfully", "");
                this.changeRadioButton("D");
                this.close(myForm);
                this.photoURL = null;
                this.imageURL = null;
                this.uploadedAttachmentStr = "";
              } else {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            },
            (err) => {
              if (err["ok"] == false) {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            }
          );
      } else if (this.branchGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1["BRANCH_ID"] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }
        } else {
          var obj1 = new Object();
          obj1["BRANCH_ID"] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api
          .notiDetailsAddBulk(
            this.userId,
            this.TITLE,
            this.DESCRIPTION,
            3,
            a,
            this.orgId,
            this.imageURL,
            this.NOTI_TYPE
          )
          .subscribe(
            (successCode) => {
              if (successCode["code"] == "200") {
                this.isSpinning = false;
                this.message.success("Notification Send Successfully", "");
                this.changeRadioButton("B");
                this.close(myForm);
                this.photoURL = null;
                this.imageURL = null;
                this.uploadedAttachmentStr = "";
              } else {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            },
            (err) => {
              if (err["ok"] == false) {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            }
          );
      } else if (this.designationGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1["DESIGNATION_ID"] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }
        } else {
          var obj1 = new Object();
          obj1["DESIGNATION_ID"] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api
          .notiDetailsAddBulk(
            this.userId,
            this.TITLE,
            this.DESCRIPTION,
            4,
            a,
            this.orgId,
            this.imageURL,
            this.NOTI_TYPE
          )
          .subscribe(
            (successCode) => {
              if (successCode["code"] == "200") {
                this.isSpinning = false;
                this.message.success("Notification Send Successfully", "");
                this.changeRadioButton("DE");
                this.close(myForm);
                this.imageURL = null;
                this.photoURL = null;
                this.uploadedAttachmentStr = "";
              } else {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            },
            (err) => {
              if (err["ok"] == false) {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            }
          );
      } else if (this.entireOrg) {
        this.isSpinning = true;
        var a = [];

        var obj1 = new Object();
        obj1["ORG_ID"] = this.orgId;
        a.push(Object.assign({}, obj1));

        this.api
          .notiDetailsAddBulk(
            this.userId,
            this.TITLE,
            this.DESCRIPTION,
            5,
            a,
            this.orgId,
            this.imageURL,
            this.NOTI_TYPE
          )
          .subscribe(
            (successCode) => {
              if (successCode["code"] == "200") {
                this.isSpinning = false;
                this.message.success("Notification Send Successfully", "");
                this.changeRadioButton("EO");
                this.close(myForm);
                this.uploadedAttachmentStr = "";
                this.photoURL = null;
                this.imageURL = null;
              } else {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            },
            (err) => {
              if (err["ok"] == false) {
                this.isSpinning = false;
                this.message.error("Failed to Send Notification", "");
              }
            }
          );
      }
      myForm.reset();
    }
  }

  fileURL: File = null;
  photoURL: any;
  imageSrc1: string;
  videoUrl: File = null;
  ATTACHMENT: any;

  // onFileSelected(event: any) {
  //   if (this.NOTI_TYPE == 'F') {
  //     if (event.target.files[0].type != 'video/mp4' ||
  //       event.target.files[0].type == 'video') {
  //       this.fileURL = <File>event.target.files[0];
  //       const reader = new FileReader();
  //       if (event.target.files && event.target.files.length) {
  //         const [file] = event.target.files;
  //         this.imageUpload()
  //         reader.readAsDataURL(file);
  //       }
  //     } else {
  //       this.message.error("Please ckeck file type ", "");
  //       this.fileURL = null;
  //     }
  //   }
  //   if (this.NOTI_TYPE == 'I') {
  //     if (event.target.files[0].type != 'video/mp4' ||
  //       event.target.files[0].type == 'video') {
  //       this.fileURL = <File>event.target.files[0];
  //       const reader = new FileReader();
  //       if (event.target.files && event.target.files.length) {
  //         const [file] = event.target.files;
  //         this.imageUpload()
  //         reader.readAsDataURL(file);
  //         if (event.target.files[0].type == "image/jpeg" ||
  //           event.target.files[0].type == "image/png" ||
  //           event.target.files[0].type == "image/jpg") {
  //           reader.onload = () => {
  //             this.imageSrc1 = reader.result as string;
  //             this.photoURL = this.imageSrc1;
  //           };
  //         }
  //       }
  //     } else {
  //       this.message.error("Please ckeck file type ", "");
  //       this.fileURL = null;
  //     }
  //   }
  //   if (this.NOTI_TYPE == 'V') {
  //     if (event.target.files[0].type == 'video/mp4' ||
  //       event.target.files[0].type == 'video') {
  //       this.fileURL = <File>event.target.files[0];
  //       this.imageUpload()
  //       const reader = new FileReader();
  //       if (event.target.files && event.target.files.length) {
  //         const [file] = event.target.files;
  //         reader.readAsDataURL(file);
  //       }
  //     }
  //     else {
  //       this.message.error('Please select only video file', '')
  //     }
  //   }
  // }

  progressBar: boolean = false;
  percent = 0;

  onFileSelectedOld(event: any) {
    // this.isSpinning = true;
    if (this.NOTI_TYPE == "F") {
      if (
        event.target.files[0].type != "video/mp4" ||
        event.target.files[0].type == "video"
      ) {
        this.fileURL = <File>event.target.files[0];
        const reader = new FileReader();
        const fileSizeInMB = event.target.files[0].size / 1024 / 1024; // Calculate size in MB

        // Check if the file size is less than 10 MB
        if (fileSizeInMB <= 5) {
          if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            this.imageUpload();
            // this.onUpload(this.fileURL.name);

            reader.readAsDataURL(file);
          }
          // this.isSpinning = false;
        } else {
          this.fileURL = null;
          this.message.error(
            "File size must be less than or equal to 5 MB",
            ""
          );
          // this.isSpinning = false;
        }
      } else {
        this.message.error("Please ckeck file type ", "");
        this.fileURL = null;
        // this.isSpinning = false;
      }
    }
    if (this.NOTI_TYPE == "I") {
      if (
        event.target.files[0].type != "video/mp4" ||
        event.target.files[0].type == "video"
      ) {
        this.fileURL = <File>event.target.files[0];
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          this.imageUpload();
          reader.readAsDataURL(file);
          if (
            event.target.files[0].type == "image/jpeg" ||
            event.target.files[0].type == "image/png" ||
            event.target.files[0].type == "image/jpg"
          ) {
            reader.onload = () => {
              this.imageSrc1 = reader.result as string;
              this.photoURL = this.imageSrc1;
            };
            // this.isSpinning = false;
          }
        } else {
          // this.isSpinning = false;
        }
      } else {
        this.message.error("Please ckeck file type ", "");
        this.fileURL = null;
        // this.isSpinning = false;
      }
    }
    if (this.NOTI_TYPE == "V") {
      if (
        event.target.files[0].type == "video/mp4" ||
        event.target.files[0].type == "video"
      ) {
        this.fileURL = <File>event.target.files[0];
        this.imageUpload();
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          reader.readAsDataURL(file);
          // this.isSpinning = false;
        } else {
          // this.isSpinning = false;
        }
      } else {
        this.message.error("Please select only video file", "");
        // this.isSpinning = false;
      }
    }
  }

  onFileSelected(event: any) {
    this.isSpinning = true;
    if (!event.target.files || event.target.files.length === 0) {
      this.isSpinning = false;

      this.message.error("No file selected.", "");
      return;
    }

    const file = event.target.files[0];
    const fileType = file.type;
    const fileSizeInMB = file.size / 1024 / 1024; // Calculate size in MB

    // Check file size restriction
    if (this.NOTI_TYPE == "V" || this.NOTI_TYPE == "F") {
      if (fileSizeInMB > 10) {
        this.fileURL = null;
        this.isSpinning = false;
        if (this.NOTI_TYPE == "V") {
          this.message.error(
            "Video size must be less than or equal to 10 MB",
            ""
          );
        } else {
          this.message.error(
            "File size must be less than or equal to 10 MB",
            ""
          );
        }
        return;
      }
    } else {
      if (fileSizeInMB > 5) {
        this.fileURL = null;
        this.isSpinning = false;
        this.message.error("Image size must be less than or equal to 5 MB", "");
        return;
      }
    }

    const reader = new FileReader();

    if (this.NOTI_TYPE === "F") {
      // Allow all files but restrict size
      this.fileURL = <File>file;
      reader.readAsDataURL(file);
      this.imageUpload();
    } else if (this.NOTI_TYPE === "I") {
      // Image file validation
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "image/jpg"
      ) {
        this.fileURL = <File>file;
        reader.onload = () => {
          this.imageSrc1 = reader.result as string;
          this.photoURL = this.imageSrc1;
        };
        reader.readAsDataURL(file);
        this.imageUpload();
      } else {
        this.fileURL = null;
        this.isSpinning = false;

        this.message.error(
          "Please select a valid image file (JPEG, PNG, JPG).",
          ""
        );
      }
    } else if (this.NOTI_TYPE === "V") {
      // Video file validation
      if (fileType === "video/mp4") {
        this.fileURL = <File>file;
        reader.readAsDataURL(file);
        this.imageUpload();
      } else {
        this.fileURL = null;
        this.isSpinning = false;

        this.message.error("Please select a valid video file (MP4).", "");
      }
    } else {
      // Invalid NOTI_TYPE
      this.fileURL = null;
      this.isSpinning = false;

      this.message.error("Invalid file type selected.", "");
    }
  }

  folderName = "notifications";
  uploadedAttachmentStr: string;
  timer: any;

  imageUpload() {
    this.uploadedAttachmentStr = "";
    if (this.fileURL) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split(".").pop();
      // var url = "N" + number + "." + fileExt;
      var url = this.fileURL.name;
      this.isLoadingOne = true;
      this.isSpinning = true;
      // this.progressBar = true;

      this.api
        .onUpload2(this.folderName, this.fileURL, url)
        .subscribe((res: any) => {
          if (res["code"] == 200) {
            this.message.success(
              this.fileURL.name + "" + " file uploaded successfully.",
              ""
            );
            this.isLoadingOne = false;
            this.isSpinning = false;
          } else {
            this.message.error("Something went wrong", "");
            this.progressBar = false;

            this.isLoadingOne = false;
            this.isSpinning = false;
          }
        });
      this.uploadedAttachmentStr =
        this.api.retriveimgUrl + "notifications/" + url;
    } else {
      this.uploadedAttachmentStr = "";
      this.isLoadingOne = false;
      this.isSpinning = false;
    }
  }
  imageUploadNew() {
    this.uploadedAttachmentStr = "";
    if (this.fileURL) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split(".").pop();
      // var url = "N" + number + "." + fileExt;
      var url = this.fileURL.name;
      this.isLoadingOne = true;
      this.isSpinning = true;
      this.progressBar = true;

      this.api
        .onUploadProgressBar(this.folderName, this.fileURL, url)
        .subscribe((res: any) => {
          if (res.type === HttpEventType.Response) {
          }
          if (res.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * res.loaded) / res.total);
            this.percent = percentDone;
            if (this.percent == 100) {
              this.isSpinning = false;
            }
          }

          if (res.body["code"] == 200) {
            this.message.success(
              this.fileURL.name + "" + " file uploaded successfully.",
              ""
            );
            this.isSpinning = false;
          } else {
            this.message.error(
              "Failed To Save " + this.fileURL.name + "" + " file.",
              ""
            );
            this.isSpinning = false;
            this.progressBar = false;
            this.percent = 0;
          }

          //     if (res["code"] == 200) {
          //       this.message.success(
          //         this.fileURL.name + "" + " file uploaded successfully.",
          //         ""
          //       );
          //       this.isLoadingOne = false;
          //       this.isSpinning = false;
          //     } else {
          //       this.message.error("Something went wrong", "");
          // this.progressBar = false;

          //       this.isLoadingOne = false;
          //       this.isSpinning = false;
          //     }
        });
      this.uploadedAttachmentStr =
        this.api.retriveimgUrl + "notifications/" + url;
    } else {
      this.uploadedAttachmentStr = "";
      this.isLoadingOne = false;
      this.isSpinning = false;
    }
  }

  onUpload(url: string): void {
    if (this.fileURL) {
      this.isSpinning = true;
      const headers = { "Content-Type": "multipart/form-data" }; // Adjust headers if necessary

      this.api.uploadFile(this.folderName, url, this.fileURL).subscribe(
        (response: any) => {
          if (response.status === "progress") {
            this.percent = response.percent;
            if (this.percent === 100) {
              this.isSpinning = false;
            }
          } else if (response.status === "response") {
            if (response.body && response.body.code === 200) {
              this.message.success("CGHS/AMA Uploaded Successfully...", "");
              this.isSpinning = false;
            } else {
              this.message.error("Failed To Save File...", "");
              this.isSpinning = false;
              this.progressBar = false;
              this.percent = 0;
            }
          }
        },
        (error) => {
          this.message.error("Error uploading file...", "");
          this.isSpinning = false;
          this.progressBar = false;
          this.percent = 0;
        }
      );
    }
  }

  onFileSelectedcghsama(event: any) {
    if (event.target.files[0].type == "application/pdf") {
      this.isSpinning = true;
      this.fileURL = <File>event.target.files[0];

      if (this.fileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split(".").pop();
        // var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        // var url = '';
        // url = d == null ? '' : d + number + '.' + fileExt;
        // this.cghsamalatterurl = url;
        // if (
        //   this.data2.CGHS_AMA != undefined &&
        //   this.data2.CGHS_AMA.trim() != ''
        // ) {
        //   var arr = this.data2.CGHS_AMA.split('/');
        //   if (arr.length > 1) {
        //     url = arr[5];
        //   }
        // }
      }
      // this.data2.CGHS_AMA = this.urllink3;
      var url = this.fileURL.name;
      this.progressBar = true;
      this.timer = this.api
        .onUploadProgressBar(this.folderName, this.fileURL, url)
        .subscribe((res: any) => {
          if (res.type === HttpEventType.Response) {
          }
          if (res.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * res.loaded) / res.total);
            this.percent = percentDone;
            if (this.percent == 100) {
              this.isSpinning = false;
            }
          }

          if (res.body["code"] == 200) {
            this.message.success("CGHS/AMA Uploaded Successfully...", "");
            this.isSpinning = false;
            // this.data2.CGHS_AMA = this.cghsamalatterurl;
          } else {
            this.message.error("Failed To Save File...", "");
            this.isSpinning = false;
            this.progressBar = false;
            this.percent = 0;
            // this.data2.CGHS_AMA = null;
          }
        });
    } else {
      this.message.error("Please Select Only PDF File", "");
      this.fileURL = null;
      // this.data2.CGHS_AMA = null;
      this.isSpinning = false;
    }
  }

  clear() {
    this.message.warning(this.fileURL.name + "" + " file removed !", "");
    this.fileURL = null;
    this.photoURL = null;
  }

  isFileVisible: boolean = false;
  viewFileName: any;
 
  viewFile() {
    this.isFileVisible = true;
    if (this.fileURL.name) {
      this.viewFileName = this.fileURL.name;
      this.sanitizedLink = this.getS(this.viewFileName); // Precompute the sanitized link
    } else {
      this.viewFileName = null;
      this.sanitizedLink = null;
    }
  }

  handleCancel(): void {
    this.viewFileName = null;
    this.sanitizedLink = null;
    this.isFileVisible = false;
  }

  imgUrl = this.api.retriveimgUrl;
  sanitizedLink: any;
  getS(link: string) {
    if (
      this.sanitizedLink == undefined ||
      this.sanitizedLink == null ||
      this.sanitizedLink == ""
    ) {
      var a = this.imgUrl + "notifications/" + link + "#toolbar=0";
      this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);
      return this.sanitizedLink;
    }
  }

  zoomLevel = 1; // Default zoom level
  rotationDegree = 0;

  zoomIn() {
    this.zoomLevel = Math.min(this.zoomLevel + 0.1, 2); // Limit max zoom level
    this.updateTransform();
  }

  zoomOut() {
    if (this.zoomLevel > 0.5) {
      // Limit minimum zoom level
      this.zoomLevel -= 0.1;
      this.updateTransform();
    }
  }

  rotate() {
    this.rotationDegree = (this.rotationDegree + 90) % 360; // Keep rotation within 0–360 degrees
    this.updateTransform();
  }

  updateTransform() {
    const iframe = this.iframeElement.nativeElement;
    iframe.style.transform = `scale(${this.zoomLevel}) rotate(${this.rotationDegree}deg)`;
  }
}
